import React from "react"
import { Link } from "gatsby"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"

import { theme } from "../../../styles/theme"

const IrradiationIndex = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - Irradiation" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/"
        location={location}
      />

      <div className="mt-gap-2" css={theme.components.row}>
        <p className="mb-gap-2">
          This section provides guidance on situations when the use of
          irradiated blood components is appropriate. The guidance may not be
          appropriate in all patient situations, and individual circumstances
          may dictate an alternative approach.
        </p>
        <p className="mb-gap-2">
          Irradiated components have been treated with radiation (by x-rays or
          other forms of radioactivity) to prevent transfusion associated
          graft-versus-host disease (TA-GvHD).
        </p>
        <p className="mb-gap-2">Please select all applicable.</p>

        <ul css={theme.components.linkButtonList}>
          <li>
            <Link
              to="/requirements/irradiation/hla-matched-components"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              HLA matched components
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/transfusion-from-relative"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Transfusion from relative
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/intrauterine-transfusion"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Intrauterine transfusion (IUT)
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/exchange-transfusion"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Exchange blood transfusion (EBT) for neonates
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/immunosuppressant-therapies"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Immunosuppressant therapies (e.g. Purine analogues)
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/hsc-recipients"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Allogeneic haematopoietic stem cell (HSC) transplant recipients
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/hsc-donors"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Allogeneic haematopoietic stem cell (HSC) donors
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/hsc-transplant"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Autologous haematopoietic stem cell (HSC) transplant patients
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/lymphocyte-immunodeficiency"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Congenital T lymphocyte immunodeficiency
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/hodgkin-lymphoma"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Hodgkin lymphoma
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/granulocyte-administration"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              Granulocyte administration
            </Link>
          </li>
          <li>
            <Link
              to="/requirements/irradiation/car-t-therapy"
              css={[
                theme.components.button,
                theme.components.buttonGrey,
                theme.components.buttonLink,
                theme.components.listLinkButton,
              ]}
            >
              CAR-T therapy
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export default IrradiationIndex
